import React, { useState } from 'react'
import { Fade } from 'react-reveal'
import languages from '../../data/languages.json'
import LightSpeed from 'react-reveal/LightSpeed';

const Languages = () => {
    const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <Fade duration={1000}>
      <div style={{ position: 'relative', width: '100%', maxWidth: 600 }}>
            <div
              className={`language-wrapper${!imageLoaded ? ' hide' : ''}`}
            >
              <h2>Languages</h2>
              <div className="languages">
                { languages.languages.map((language) => {
                  return (
                    <LightSpeed left duration={1000} key={language.id}>
                    <div className="language-bar-wrapper">
                        <div className="language">
                            <img
                                className="language-image"
                                alt="robert nicjoo profile"
                                src={language.flag}
                                onLoad={() => setImageLoaded(true)}
                            /> <br />
                            <div className='lang-text'>
                                <span className='lang-name'>{language.name}</span> <br />
                                <span className='lang-level'>{language.level}</span> <br />
                            </div>
                        </div>
                    </div>
                    </LightSpeed>
                  )
                })}
              </div>
            </div>
      </div>
    </Fade>
  )
}

export default Languages
